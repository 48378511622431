<template>
  <div class="switch-componet-wrapper" @click="changeSwitchValue">
    <div class="switch-wrapper" :class="{'off' : !value, 'on':value}"><div class="circle"></div></div>
  </div>
</template>

<script>
export default {
   props:["value" ,"productId"],
 methods:{
changeSwitchValue(){
   this.$emit('input', !this.value)
   this.SpecialProduct()
},
SpecialProduct(){
   this.$http.productsService
        .PutSpecialProduct(this.productId, !this.value)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
 },
 data() {
    return {
       
    };
  },
};
</script>

<style>
.switch-componet-wrapper {
  display: flex;
}
.switch-wrapper {
  width:38px;
  min-height: 17px;
  display: flex;
  cursor: pointer;
  border-radius: 22px;
  align-items: center;
  padding: 2px;
  transition: all 0.5s;
}
.on {
  background: black;
  justify-content: flex-end;
}
.off {
  background: gray;
  justify-content: flex-start;
}
.circle {
  background: #fff;
  width: 14px;
  height: 14px;
  border-radius: 18px;
}
</style>
